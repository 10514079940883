<template>
  <div v-if="product" class="product-box">
    <a
      :href="`https://${apiUrl.includes('staging') ? 'staging.' : ''}whizliz.com/product/` + product.slug"
      target="_blank"
    >
      <div class="product-img">
        <b-img-lazy
          :alt="product.name"
          :title="product.name"
          :src="product.product_images[0].medium_image_url"
          @error.native="replaceByDefaultImage"
          :class="{
            'p-5 default-image': product.product_images[0].medium_image_url.includes(
              'default-image'
            ),
          }"
        />
        <div class="product-badge">
          <div v-if="product.total_stock == 0" class="nostock-badge">
            SOLD OUT
          </div>
          <div v-else-if="isNew(product.created_date) <= 2" class="newin-badge">
            NEW IN
          </div>
          <div v-else-if="product.discount_type == 1" class="sale-badge">
            <span class="pt-1">SALE</span>
          </div>
          <div v-else-if="product.price_display < product.price" class="sale-badge">
            SAVE<span>{{ product.discount }}%</span>
          </div>
        </div>
      </div>
      <div class="product-info">
        <div class="product-brand">
          {{ product.brand_name }}
        </div>
        <div class="product-name">
          {{ product.name }}
        </div>
        <div class="product-price pt-2" v-if="product.checkout_type != 1">
          <a
            :class="{
              'sale-price': product.price_display < product.price,
            }"
          >
            IDR {{ formatPrice(product.price_display) }},-
            <span v-if="product.price_display < product.price" class="old-price"
              >IDR {{ formatPrice(product.price) }},-</span
            > </a
          ><br v-if="product.price_display == product.price" />
          <small
            >IDR {{ formatPrice(product.price_display / 12) }} / 12 Mon</small
          >
        </div>
        <div class="p-2" v-else>
          <p class="border-gold text-gold d-inline-block m-0 w-75">
            <small><i>Liz-Point</i></small
            ><br />
            {{ formatPrice(product.price_display) }}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: "ProductBox",
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    }
  },
  props: {
    product: Object,
  },
  methods: {
    isNew(date) {
      // https://stackoverflow.com/a/3224854
      const date1 = new Date(date);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    formatPrice: (value) =>
      value ? value.toLocaleString("id", { maximumFractionDigits: 2 }) : value,
    replaceByDefaultImage() {
      this.product.product_images[0].medium_image_url = require("@/assets/img/default-image.png");
    }
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.product-box {
	position: relative;
	max-width: 100%;
	height: 100%;
	text-align: center;
	border: 1px solid transparent;
	border-radius: .5rem;
	z-index: 0;
}

.product-box a {
	color: #000
}

.product-info {
	padding: 5px;
	margin: auto;
}

.product-badge {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.sale-badge {
	text-align: center;
	width: 40px;
	height: 40px;
	border: 1px solid #F36152;
	font-size: 11px;
	border-radius: 100px;
	padding: 7px 0;
	line-height: 13px;
	color: #F36152;
}

.nostock-badge {
	text-align: center;
	width: 40px;
	height: 40px;
	border: 1px solid #000;
	font-size: 11px;
	border-radius: 100px;
	padding: 7px 0;
	line-height: 13px;
	color: #000;
}

.newin-badge {
	text-align: center;
	width: 40px;
	height: 40px;
	border: 1px solid #80c2c8;
	font-size: 11px;
	border-radius: 100px;
	padding: 7px 1px;
	line-height: 13px;
	color: #80c2c8;
}

.sale-badge span {
	display: inline-block;
	text-align: center;
}

.product-img {
  position: relative;
}

@media (min-width: 992px) {
	.product-img {
		height: 250px !important;
		/* background: #f6f6f6; */
	}
}

.product-img img {
	width: auto;
	height: 100%;
	object-fit: contain;
	border-radius: .5rem;
}

.product-save {
	position: absolute;
	left: 8px;
	font-size: 20px;
}

.product-save a {
	color: #999;
}

.product-save a:hover {
	color: #F36052;
}

.product-brand {
	font-weight: 600;
	font-size: 14px;
}

.product-name {
	font-size: 13px;
}

.product-status, .terms {
	font-size: 11px;
	white-space: nowrap;
}

.product-price {
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0;
	color: #000000;
}

.product-price a {
	/* color: #000 !important; */
	font-family: 'Roboto Bold';
	font-size: 1.02rem;
}

.product-price a.sale-price {
	color: #d89700;
	display: block;
}

span.old-price {
	color: #241c15b3;
	font-size: 11px;
	text-decoration: line-through;
	font-family: 'Roboto';
	display: block;
}
</style>
