var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product)?_c('div',{staticClass:"product-box"},[_c('a',{attrs:{"href":`https://${_vm.apiUrl.includes('staging') ? 'staging.' : ''}whizliz.com/product/` + _vm.product.slug,"target":"_blank"}},[_c('div',{staticClass:"product-img"},[_c('b-img-lazy',{class:{
          'p-5 default-image': _vm.product.product_images[0].medium_image_url.includes(
            'default-image'
          ),
        },attrs:{"alt":_vm.product.name,"title":_vm.product.name,"src":_vm.product.product_images[0].medium_image_url},nativeOn:{"error":function($event){return _vm.replaceByDefaultImage.apply(null, arguments)}}}),_c('div',{staticClass:"product-badge"},[(_vm.product.total_stock == 0)?_c('div',{staticClass:"nostock-badge"},[_vm._v(" SOLD OUT ")]):(_vm.isNew(_vm.product.created_date) <= 2)?_c('div',{staticClass:"newin-badge"},[_vm._v(" NEW IN ")]):(_vm.product.discount_type == 1)?_c('div',{staticClass:"sale-badge"},[_c('span',{staticClass:"pt-1"},[_vm._v("SALE")])]):(_vm.product.price_display < _vm.product.price)?_c('div',{staticClass:"sale-badge"},[_vm._v(" SAVE"),_c('span',[_vm._v(_vm._s(_vm.product.discount)+"%")])]):_vm._e()])],1),_c('div',{staticClass:"product-info"},[_c('div',{staticClass:"product-brand"},[_vm._v(" "+_vm._s(_vm.product.brand_name)+" ")]),_c('div',{staticClass:"product-name"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),(_vm.product.checkout_type != 1)?_c('div',{staticClass:"product-price pt-2"},[_c('a',{class:{
            'sale-price': _vm.product.price_display < _vm.product.price,
          }},[_vm._v(" IDR "+_vm._s(_vm.formatPrice(_vm.product.price_display))+",- "),(_vm.product.price_display < _vm.product.price)?_c('span',{staticClass:"old-price"},[_vm._v("IDR "+_vm._s(_vm.formatPrice(_vm.product.price))+",-")]):_vm._e()]),(_vm.product.price_display == _vm.product.price)?_c('br'):_vm._e(),_c('small',[_vm._v("IDR "+_vm._s(_vm.formatPrice(_vm.product.price_display / 12))+" / 12 Mon")])]):_c('div',{staticClass:"p-2"},[_c('p',{staticClass:"border-gold text-gold d-inline-block m-0 w-75"},[_vm._m(0),_c('br'),_vm._v(" "+_vm._s(_vm.formatPrice(_vm.product.price_display))+" ")])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',[_vm._v("Liz-Point")])])
}]

export { render, staticRenderFns }