import { render, staticRenderFns } from "./ProductBox.vue?vue&type=template&id=45b952ef&scoped=true"
import script from "./ProductBox.vue?vue&type=script&lang=js"
export * from "./ProductBox.vue?vue&type=script&lang=js"
import style0 from "./ProductBox.vue?vue&type=style&index=0&id=45b952ef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b952ef",
  null
  
)

export default component.exports